import React, { useEffect, useState } from "react"

// Utilities
import { isObjectEmpty } from "../../../utilities/checkValidity"
import { formatProductTitle, removeTM } from "../../../utilities/formatProductInfo"

/**
 * @param shopifyData - shopifyData
 * @param shopifyVariant - data for the shopify variant
 * @param product - data for the static product
 * @param totalPrice - price * quantity of the selected variant
 * @param paymentType - the selected payment type
 * @param sellingPlan - the selected selling plan
 * @param paymentPlanInterval - the payment plan interval
 * @param modifierClass - modifier class if needed
 * @param isOffer - whether the box is offer
 * */

const ProductPrice = ({
  shopifyData,
  shopifyVariant,
  product,
  totalPrice,
  paymentType,
  sellingPlan,
  paymentPlanInterval = 0,
  modifierClass = "",
  isOffer = false,
}) => {
  const [productsIncluded, setProductsIncluded] = useState([])
  useEffect(() => {
    if (isObjectEmpty(product) || !shopifyData) return

    let shopifyProductsData = []
    let reorderedList = []

    // Get the price and title of the shopify product that's included in the bundle
    if (product.productsIncluded && product.productsIncluded.length) {
      shopifyData.forEach(shopifyProduct => {
        shopifyProduct.variants.forEach(variant => {
          if (product.productsIncluded.includes(variant.id)) {
            shopifyProductsData = [
              ...shopifyProductsData,
              {
                id: variant.id,
                title: formatProductTitle(removeTM(shopifyProduct.title), shopifyProduct),
                price: variant.price,
              },
            ]
          }
        })
      })

      // Reorder the products according to the order in the json object
      product.productsIncluded.forEach(item => {
        shopifyProductsData.forEach(productData => {
          if (item === productData.id) {
            reorderedList = [...reorderedList, productData]
          }
        })
      })
    }
    setProductsIncluded(reorderedList)
  }, [shopifyData, product])

  return (
    <div className={`product__price ${modifierClass || ""}`}>
      {shopifyVariant && product ? (
        <>
          {product.offer && (
            <div className="d-flex flex-column">
              <p className="price price--sm txt--blackshade fw--regular mb--15i">
                <img src="/images/truck.svg" alt="" width="23" height="19" className="mr--5" />
                {product.offerContent.offerProduct.toUpperCase()} Deal!{" "}
                <strong className="txt--uppercase txt--pink fw--bold">get free shipping!!</strong>
              </p>
              <p className="price price--md txt--blackshade">
                Protein Bundle: 
                <span className="ml--10 price--strikethrough">${Number(+shopifyVariant.compareAtPrice + +product.freeGift).toFixed(2)}</span>
              </p>
              <p className="price price--md txt--blackshade mb--25i">
                Shipping (USA):{" "}
                <span className="price--strikethrough ml--10">$10.95</span>
              </p>
              <p className="price price--md txt--blackshade">
               Total Bundle Value:
               <span className="ml--10">${Number(+shopifyVariant.compareAtPrice + +product.freeGift + 10.95).toFixed(2)}</span>
              </p>

              <div className="price price--new">
                <span className="price__text">
                  <i className="fas fa-chevron-double-right" />
                  YOUR PRICE:
                </span>
                <span className="price__value">
                  ${!totalPrice ? `${Number(shopifyVariant.price).toFixed(2)}` : `${Number(totalPrice).toFixed(2)}`}
                </span>
                <span class="txt--blackshade d-inline-block">(Save Over {Math.floor(100 - (totalPrice / Number(+shopifyVariant.compareAtPrice + +product.freeGift + 10.95).toFixed(2) * 100))}%)</span>
              </div>
            </div>
          )}

          {shopifyVariant.compareAtPrice ? (
            <>
              {!product.offer && (
                <>
                  {product.type === "bundles" ? (
                    <div>
                      {productsIncluded.length > 0 && (
                        <div className="product__included">
                          {productsIncluded.map(product => (
                            <div key={product.shopifyId} className="price">
                              <span className="price__text" dangerouslySetInnerHTML={{ __html: product.title }} />
                              <span>${Number(product.price)}</span>
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="price price--regular">
                        <span className={`price__text ${product.noRegularText && "price__text--lg"}`}>
                          {product.noRegularText ? "Total Bundle Value:" : "Regular Price:"}
                        </span>
                        <span className="price--strikethrough">
                          ${Number(shopifyVariant.compareAtPrice).toFixed(2)}
                        </span>
                      </div>

                      {product.freeGift && (
                        <div className="price price--freegift">
                          <span className={`price__text ${product.noRegularText && "price__text--lg"}`}>
                            Free {product.freeGiftType}:
                          </span>
                          <span className="price--strikethrough">${product.freeGift}</span>
                        </div>
                      )}
                      {!product.noRegularText && (
                        <div className="price price--total">
                          <span className="price__text">Total Value:</span>
                          <span>${Number(+shopifyVariant.compareAtPrice + +product.freeGift).toFixed(2)}</span>
                        </div>
                      )}
                      <div className="price price--new">
                        <span className="price__text">
                          <i className="fas fa-chevron-double-right" />
                          YOUR PRICE:
                        </span>
                        <span className="price__value">
                          $
                          {!totalPrice
                            ? `${Number(shopifyVariant.price).toFixed(2)}`
                            : `${Number(totalPrice).toFixed(2)}`}
                        </span>
                        <span className={`price__discount ${isOffer && "d-inline-block"}`}>
                          {isOffer ? product.saveOfferText : product.saveText}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="price price--regular">
                        <span className="price__text">Regular Price:</span>
                        <span className="price--strikethrough">
                          ${Number(shopifyVariant.compareAtPrice).toFixed(2)}
                        </span>
                      </div>
                      <div className="price price--new">
                        <span className="price__text">
                          <i className="fas fa-chevron-double-right" />
                          SALE PRICE:
                        </span>
                        <span className="price__value">
                          ${Number(totalPrice).toFixed(2)}
                          {paymentType === "two" && sellingPlan !== "No" && (
                            <small> x {paymentPlanInterval} months</small>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {!product.offer && (
                <p className="txt--pink">
                  <strong>${Number(totalPrice).toFixed(2)}</strong>
                </p>
              )}
            </>
          )}
        </>
      ) : (
        <span className="txt-loader" />
      )}
    </div>
  )
}
export default ProductPrice
